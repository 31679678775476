var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "LBLBASEINFO" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && !_vm.data.approvalStatusCd
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.mappingType,
                                  label: "LBLSAVE",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveInfo,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: true,
                            editable: _vm.editable && !_vm.updateMode,
                            label: "LBLREGDATE",
                            name: "dailyResultDt",
                          },
                          model: {
                            value: _vm.data.dailyResultDt,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "dailyResultDt", $$v)
                            },
                            expression: "data.dailyResultDt",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-field", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            data: _vm.data,
                            deptValue: "deptCd",
                            type: "dept_user",
                            label: "작성부서 & 작성자",
                            name: "userId",
                          },
                          model: {
                            value: _vm.data.userId,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "userId", $$v)
                            },
                            expression: "data.userId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: true,
                            editable: _vm.editable && !_vm.updateMode,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.data.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "plantCd", $$v)
                            },
                            expression: "data.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c("c-table", {
                ref: "grid",
                attrs: {
                  title: "폐수 전량위탁 처리정보",
                  tableId: "grid",
                  columns: _vm.grid.columns,
                  data: _vm.grid.data,
                  usePaging: false,
                  hideBottom: true,
                  columnSetting: false,
                  isFullScreen: false,
                  filtering: false,
                  isExcelDown: false,
                  editable: _vm.editable,
                  gridHeight: "300px",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c("c-table", {
                ref: "grid1",
                attrs: {
                  title: "원료 또는 첨가제 등의 사용량",
                  tableId: "grid1",
                  columns: _vm.grid1.columns,
                  data: _vm.data.waterEntrustResultAdditives,
                  usePaging: false,
                  hideBottom: true,
                  columnSetting: false,
                  filtering: false,
                  isExcelDown: false,
                  editable: _vm.editable,
                  gridHeight: "300px",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }